

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isCreated: {},
  Xstudent: [],
  singleStudent :[]
};

const xpllorastudentSlice = createSlice({
  name: 'xpllorastudent',
  initialState,
  reducers: {
    addXpllorastudent(state, action) {
      state.isCreated = action.payload
  
    },
    clearXstudentCreated(state,action){
      state.isCreated= {}
    },
    getAllStudent(state,action) {
      state.Xstudent=action.payload
    },
    getAllStudentAdmin(state,action){
      state.Xstudent=action.payload
    },
    getSingleStudent(state,action){
      state.singleStudent=action.payload
    },

  },
});

export const { addXpllorastudent,getAllStudent ,getAllStudentAdmin,getSingleStudent,clearXstudentCreated} = xpllorastudentSlice.actions;
export default xpllorastudentSlice.reducer;
// add new xpllorastudent
export function addXstudent(id, studentData) {
  return async function addNewstudentThunk(dispatch, getState) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/xstudent/addxnewstudent/${id}`, studentData);
      const result = response.data;
      dispatch(addXpllorastudent(result));
      return result;
    } catch (error) {
      console.error('Error adding student:', error);
      throw error;
    }
  };
}
//get all student by staff
export function getXstudent(id) {
  return async function getALlThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/xstudent/getallstudent/${id}`);
      const result = response.data;
      console.log(result);
      dispatch(getAllStudent(result));
      return result;
    } catch (error) {
      console.error('Error adding student:', error);
      throw error;
    }
  };
}
//getall student by admin
export function getXstudentAdmin() {
  return async function getALlThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/xstudent/getallxstudentadmin`);
      const result = response.data;
      console.log(result);
      dispatch(getAllStudentAdmin(result));
      return result;
    } catch (error) {
      console.error('Error adding student:', error);
      throw error;
    }
  };
}

//get single student
export function getSingleXstudent(id) {
  return async function getsingleThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/xstudent/getsinglexstudent/${id}`);
      const result = response.data.Result;
      console.log(result);
      dispatch(getSingleStudent(result));
      return result;
    } catch (error) {
      console.error('Error adding student:', error);
      throw error;
    }
  };
}