import React, { useState,useEffect } from 'react'
import jsPDF from 'jspdf';
import { useLocation, useNavigate } from 'react-router-dom';

const Paymentreceipt = () => {
    const location =useLocation()
    const {paymentData} = location.state
    const [date,setDate]=useState('')
    useEffect(() => {
        const today = new Date();
        const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
            setDate(formattedDate)
      }, []);

      const navigte = useNavigate()
      const generatePdf =() =>{
        const doc = new jsPDF("p", "pt", "a5");
        const content = document.getElementById('content');

        doc.html(content, {
            callback: function (pdf) {
                pdf.save("PrePaymentReceipt.pdf");
            },
            x: 10,
            y: 10
        });
  
      }
           
  return (
    <div>
   <div className="max-w-2xl mx-auto border p-4 mt-20" >
    <div id='content'>
      <div className="text-center">
        <h1 className="text-2xl font-bold">Xpllora Education Centre</h1>
        <p>No.248, Jaffna Road, Manipay</p>
        <p>T.P: 021225 6200, 077 6168 584, 077 2908 584</p>
      </div>
      <div className='mt-5'><p className='text-center font-bold text-bluecolor'>Pre School Course Payment</p></div>
      <div className="mt-5">
        <div className="flex justify-between">
         
          <span>Date:{date}</span>
        </div>
        <div className="mt-5">
          <div className='flex space-x-3'>
            <div className='w-32'><p className='text-lg'>Course :</p></div>
            <div className='border-b-[3px] border-bluecolor  border-dotted w-80'><p>{paymentData.courseName} </p></div>
          </div>
          
        </div>
        <div className="mt-5">
        <div className='flex space-x-3'>
            <div className='w-32'><p className='text-lg'>Student Name :</p></div>
            <div className='border-b-[3px] border-bluecolor  border-dotted w-80'><p>{paymentData.studentName}</p></div>
          </div>
         
        </div>
       
       
      
        <div className="mt-5">
        <div className='flex space-x-3'>
            <div className='w-32'><p className='text-lg'>Amount :</p></div>
            <div className='border-b-[3px] border-bluecolor  border-dotted w-80'><p>{paymentData.totalamount}</p></div>
          </div>
         
        </div>
        <div className="mt-5">
        <div className='flex space-x-3'>
            <div className='w-32'><p className='text-lg'>Month :</p></div>
            <div className='border-b-[3px] border-bluecolor  border-dotted w-80'><p>{paymentData.pdfmonth}</p></div>
          </div>
         
        </div>
        <div className="mt-5">
        <div className='flex space-x-3'>
            <div className='w-32'><p className='text-lg'>Year :</p></div>
            <div className='border-b-[3px] border-bluecolor  border-dotted w-80'><p>{paymentData.selectedYear}</p></div>
          </div>
         
        </div>
      
        <div className="mt-8 ">
          <p>Authorized Signature</p>
        </div>
        <div className="mt-1  text-xs">
          <p>Note: Payments are not Refundable</p>
        </div>
       
      </div>
      </div>
      <button className="mt-4 p-2 bg-blue-500 text-white" onClick={() => generatePdf()} >Generate PDF</button>
    </div>
    </div>
  )
}

export default Paymentreceipt
