import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const Privateroute = ({ children }) => {
    const isLogin = useSelector((state) => state.userState2.isLogin);
    
    if (!isLogin || isLogin.loginStatus == false) {
        return <Navigate to="/login" />;
    }
    
    return children;
}

export default Privateroute;
