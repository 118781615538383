// import axios from "axios"
// import { getSingleuserFail, getSingleuserSucesss, getSinleuserRequest, userLoginFail, userLoginRequest, userLoginSuccess } from "../slice/userSlice"

// export const UserLogin = (userData) => async (dispatch) =>{
//     try{
//         dispatch(userLoginRequest())
//         const {data} = await axios.post('${process.env.REACT_APP_BASE_URL}/auth/adminlogin',userData)
//         dispatch(userLoginSuccess( data))
//     }
//     catch(error){
//         dispatch(userLoginFail(error.data.data.Error))
//     }
// }

// export const loadUser = () => async (dispatch) =>{
//     try {
//         dispatch(getSinleuserRequest())
//         const data = await axios.get('${process.env.REACT_APP_BASE_URL}/auth/singleUser')
//         dispatch(getSingleuserSucesss(data.data.result))
//     } catch (error) {
//         dispatch(getSingleuserFail(error.data.Error))
//     }
// }

// userActions.js
import axios from "axios";
import {
  getSingleuserFail,
  getSingleuserSucesss,
  getSinleuserRequest,
  logoutUserFail,
  logoutUserRequest,
  logoutUserSuccess,
  userLoginFail,
  userLoginRequest,
  userLoginSuccess,
} from "../slice/userSlice";

export const UserLogin = (userData) => async (dispatch) => {
  try {
    dispatch(userLoginRequest());
    const { data } = await axios.post(
      "${process.env.REACT_APP_BASE_URL}/auth/adminlogin",
      userData
    );
    dispatch(userLoginSuccess(data));
  } catch (error) {
    dispatch(userLoginFail(error.data.data.Error));
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    dispatch(getSinleuserRequest());
    const { data } = await axios.get("${process.env.REACT_APP_BASE_URL}/auth/singleUser");
    dispatch(getSingleuserSucesss(data.data));
  } catch (error) {
    dispatch(getSingleuserFail(error.data.Error));
  }
};

export const logOut = () => async(dispatch) =>{
  try {
    dispatch(logoutUserRequest())
    const data = await axios.get("${process.env.REACT_APP_BASE_URL}/auth/logoutuser")
    dispatch(logoutUserSuccess(data.data))
    
  } catch (error) {
    dispatch(logoutUserFail(error.data.Error))
  }
}