// import { createSlice } from "@reduxjs/toolkit";

// const userSlice = createSlice({
//   name: "user",
//   initialState: {
//     user: {},
//     isAuthenticated: false,
//     loading: false,
//   },
//   reducers: {
//     userLoginRequest(state, action) {
//       return {
//         ...state,
//         loading: true,
//       };
//     },
//     userLoginSuccess(state, action) {
//       return {
//         loading: false,
//         user: action.payload,
//         isAuthenticated: true,
//       };
//     },
//     userLoginFail(state, action) {
//       return {
//         ...state,
//         isAuthenticated: false,
//         loading: false,
//       };
//     },
//     clearError(state, action) {
//       return {};
//     },
//     getSinleuserRequest(state, action) {
//       return {
//         ...state,
//         loading: true,
//       };
//     },
//     getSingleuserSucesss(state, action) {
//       return {
//         loading: false,
//         user: action.payload,
//       };
//     },
//     getSingleuserFail(state, action) {
//       return {
//         ...state,
//         loading: false,
//       };
//     },
//   },
// });

// const { actions, reducer } = userSlice;

// export const { userLoginRequest, userLoginSuccess, userLoginFail, getSinleuserRequest,getSingleuserSucesss,getSingleuserFail } = actions;

// export default reducer;

// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Function to load user data from local storage
const loadUserFromStorage = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: loadUserFromStorage() || {}, // Load user from local storage if available
     isAuthenticated:  !!localStorage.getItem("token"), // Check if token exists in local storage
    loading: false,
  },
  reducers: {
    userLoginRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    userLoginSuccess(state, action) {
      // Save user data and authentication status to local storage
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem("token", action.payload.token);

      return {
        // ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: true,
      };
    },
    userLoginFail(state, action) {
      // Clear user data and authentication status from local storage
      localStorage.removeItem("user");
      localStorage.removeItem("token");

      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    },
    clearError(state, action) {
      return {};
    },
    getSinleuserRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    getSingleuserSucesss(state, action) {
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    },
    getSingleuserFail(state, action) {
      return {
        ...state,
        loading: false,
      };
    },
    logoutUserRequest(state,action){
      return{
        ...state,
        loading:true
      }
    },
    logoutUserSuccess(state,action){
      localStorage.removeItem("user");
      localStorage.removeItem("token");

      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    },
    logoutUserFail(state,action){
return{
  ...state,
  loading:false
}
    }

  },
});

const { actions, reducer } = userSlice;

export const {
  userLoginRequest,
  userLoginSuccess,
  userLoginFail,
  getSinleuserRequest,
  getSingleuserSucesss,
  getSingleuserFail,
  logoutUserRequest,
  logoutUserSuccess,
  logoutUserFail
} = actions;

export default reducer;
