import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialstate={
    isCreate:{},
    staff:[],
    singleStaff:[],
    isDelete:{},
    isUpdated:{}
}

const academicstaffSlic  = createSlice({
    name: "academicstaff",
    initialState: initialstate,
    reducers: {
        creatstaff(state,action){
            state.isCreate=action.payload
        },
        clearcreatestaff(state,action){
            state.isCreate={}
        },
        getStaff(state,action){
            state.staff=action.payload
        },
        getSingleStaff(state,action){
            state.singleStaff = action.payload
        },
        clearSingleStaff(state,action){
            state.isCreate= action.payload
        },
        UpdataAcademicStaff(state,action){
           state.isUpdated = action.payload
        },
        clearStaffUpdate(state,action){
            state.isUpdated ={}
        }
    }
})

export const {creatstaff,clearcreatestaff,getStaff,getSingleStaff,clearSingleStaff,UpdataAcademicStaff,clearStaffUpdate} = academicstaffSlic.actions
export default academicstaffSlic.reducer

export function addAcademicStaff (staff){
    return async  function addstaffThunk (dispatch,getState)  {
        const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/acdemic/newstaff`,staff)
        const result = data.data
        dispatch(creatstaff(result))
        console.log(result);
    }
}

export function getAcademicStaff (){
    return async  function getstaffThunk (dispatch,getState)  {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/acdemic/getallstaff`)
        const result = data.data
        dispatch(getStaff(result))
        console.log(result);
    }
}

export function getoneAcademicStaff (id){
    return async  function getstaffThunk (dispatch,getState)  {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/acdemic/getAstaff/${id}`)
        const result = data.data
        dispatch(getSingleStaff(result))
        console.log(result);
    }
}

export function deleteAcademicstaff(id){
    return async function detetestaffThunk (dispatch,getState){
        const data = await axios.delete(`${process.env.REACT_APP_BASE_URL}/acdemic/deletestaff/${id}`)
        const result = data.data
        dispatch(clearSingleStaff(result))
        console.log(result);

    }
}

export function editAcademicStaff(id,stff) {
    return async function editstaffThunk (dispatch,getState)  {
        const data = await axios.put(`${process.env.REACT_APP_BASE_URL}/acdemic/updatestaff/${id}`,stff)
        const result = data.data
        dispatch(UpdataAcademicStaff(result))
        console.log(result);
    }
}