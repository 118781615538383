import React, { useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

const Faq = () => {
  const [openQuestionId, setOpenQuestionId] = useState(null);

  const QA = [
    {
      id: 1,
      question:
        "What is the admission process for enrolling my child in your preschool?",
      answer:
        "To enroll your child in our preschool, you can start by filling out an online application form available on our website. After submitting the form, our admissions team will contact you to schedule a visit to the school.",
    },
    {
      id: 2,
      question: "What age group does your preschool cater to?",
      answer:
        "Our preschool caters to children aged 2 to 5 years old. We offer programs designed to meet the developmental needs of toddlers, nursery, and pre-kindergarten children. Each age group has a tailored curriculum to ensure age-appropriate learning and activities that promote social, emotional, cognitive, and physical development.",
    },
    {
      id: 3,
      question: "What is the teacher-to-student ratio in your classrooms?",
      answer:
        "We maintain a low teacher-to-student ratio to ensure that each child receives individualized attention and support. Typically, our classrooms have a ratio of 1 teacher to every 8-10 students.",
    },
    {
      id: 4,
      question:
        "How does your preschool handle allergies and dietary restrictions?",
      answer:
        "We take the health and safety of our students very seriously. During the enrollment process, parents are required to provide detailed information about any allergies or dietary restrictions their child may have.",
    },
    {
      id: 5,
      question:
        "What extracurricular activities and events are offered at your preschool?",
      answer:
        "Our preschool offers a wide range of extracurricular activities and events to enhance the learning experience and foster a well-rounded development. Some of the activities include music and dance classes, art and craft sessions, outdoor play, and storytelling.",
    },
  ];

  const toggleQuestion = (id) => {
    if (openQuestionId === id) {
      setOpenQuestionId(null);
    } else {
      setOpenQuestionId(id);
    }
  };

  return (
    <div className="h-auto container mx-auto flex md:flex-row flex-col space-y-5 md:space-y-0 w-full md:py-20 py-10 md:space-x-10 md:px-10 px-2">
      <div className="md:w-3/5 w-full">
        <p className="md:text-6xl text-3xl font-bold">
          Get every single answer here.
        </p>
        <p className="md:text-2xl text-xl text-gray-700 mt-7">
          A business or organization established to provide a particular
          service, typically one that involves organizing transactions.
        </p>
        {QA.map((q) => (
          <div key={q.id} className="mt-6 rounded-md p-2">
            <div
              onClick={() => toggleQuestion(q.id)}
              className={`h-16 px-3 py-2 flex md:space-x-3 space-x-1 justify-between cursor-pointer border-2 rounded-md  ${
                openQuestionId === q.id ? "border-secondary" : "border-gray-400"
              } `}
            >
              <div className="flex items-center md:px-5 px-2">
                <p className="md:text-xl md:font-semibold">{q.question}</p>
              </div>
              <div
                className={`h-full flex items-center ${
                  openQuestionId === q.id
                    ? "border-0 text-xl text-secondary"
                    : " border-2 rounded-md  border-gray-700"
                }  px-3`}
              >
                {openQuestionId === q.id ? (
                  <IoIosArrowDown />
                ) : (
                  <MdOutlineArrowBackIosNew />
                )}
              </div>
            </div>
            {openQuestionId === q.id && (
              <div className="mt-5 px-5">
                <p>{q.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="md:w-2/5 w-full h-auto bg-primary rounded-lg md:py-5 py-10 md:px-20 px-10">
        <p className="text-white font-bold md:text-5xl text-2xl">Contact Us</p>
        <div className="flex flex-col space-y-10 mt-10">
          <input
            type="text"
            placeholder="Your Name"
            className="md:py-4 py-3 px-2 rounded-xl"
          />
          <input
            type="text"
            placeholder="Your Email"
            className="md:py-4 py-3 px-2 rounded-xl"
          />
          <input
            type="text"
            placeholder="Your Phone"
            className="md:py-4 py-3 px-2 rounded-xl"
          />
          <textarea
            rows={5}
            cols={8}
            className="md:py-4 py-3 px-2 rounded-xl"
            placeholder="Write Your Comment"
          />
          <button className="bg-secondary py-4 px-2 rounded-2xl text-white">
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Faq;
