import React from 'react'
import Header from '../components/Header'
import Lodinng from '../components/Lodinng'
const COntactus = () => {
  return (
    <div>
      <Header />
      <div className='mt-80'>
    <Lodinng />
  
    </div>
    </div>
  )
}

export default COntactus
