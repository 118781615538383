import React from "react";
import Home from "./Home";
import Aboutus from "./Aboutus";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Courses from "./Courses";
import Features from "./Features";
import Newsletter from "../components/Newsletter";
import Blog from "../components/Blog";
import Faq from "../components/Faq";

const Main = () => {
  return (
    <div className="h-auto">
      <Header />
      <Home />
      <Aboutus />
      <Courses />
      <Faq />
      <Features />
      <Blog />
      <Footer />
    </div>
  );
};

export default Main;
