import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";

import Topbar from '../../components/Topbar'
import Swal from 'sweetalert2'
import { getSingleXstudent } from "../../slice/xpllorasthudent";
import { useParams } from "react-router-dom";

const Updatexstudent = () => {
    const {id} = useParams()
   console.log(id);
    const {singleStudent } = useSelector((state) => state.xstudentState);
    const { user } = useSelector((state) => state.userState);

    const dispatch = useDispatch();
    const [xstudent, setXstudent] = useState({
      name: "",
      dateofbirth: "",
      nic: "",
      address: "",
      contact_mobile: "",
      contact_lan: "",
      course: [],
      couser_elucution: [],
      parent_name: "",
      parent_contact: "",
      parent_address: "",
    });
    useEffect(() =>{
      dispatch(getSingleXstudent(id))
      console.log(singleStudent);
    
     
    },[dispatch,id])


    useEffect(() =>{
      if(Array.isArray(singleStudent) && singleStudent.length > 0){
        const studentData = singleStudent[0];
        console.log("student",studentData);
        console.log();
        setXstudent({
            name:studentData.name,
            dateofbirth: studentData.dateofbirth,
            nic: studentData.nic,
            address: studentData.address,
            contact_mobile:studentData.contact_mobile,
            contact_lan: studentData.contact_lan,
             course: JSON.parse(studentData.course) || [],
             couser_elucution:JSON.parse(studentData.course_elucution) || [],
            parent_name: studentData.parent_name,
             parent_contact: studentData.parent_contact,
             parent_address: studentData.parent_address,
        })
    }
    },[singleStudent])
   
    
    const course = [
        { id: 2, name: "Speed_Maths" },
        { id: 3, name: "Art" },
        { id: 4, name: "ICT" },
        { id: 5, name: "Chess" },
        { id: 6, name: "Shinhala" },
        { id: 7, name: "Baratham" },
        { id: 8, name: "Karathe" },
        { id: 9, name: "elucution" },
      ];
    
      const elucution = [
        { id: 1, name: "Beginer" },
        { id: 2, name: "Pre_Introductory" },
        { id: 3, name: "introductory" },
        { id: 4, name: "pre_elementary" },
        { id: 5, name: "elementary" },
        { id: 6, name: "grade_1" },
        { id: 7, name: "transitional" },
        { id: 8, name: "grade_2" },
        { id: 9, name: "intermediate" },
        { id: 10, name: "grade_3" },
        { id: 11, name: "upper_intermediate" },
      ];
    

     
    
      const [error, setError] = useState({});
      const handleChange = (e) => {
        const { name, value } = e.target;
        setXstudent({ ...xstudent, [name]: value });
        setError({ ...error, [name]: "" });
      };
      const handleCheckboxChange = (e, type) => {
        const { value, checked } = e.target;
        setXstudent((prevState) => {
          const newArray = checked
            ? [...prevState[type], value]
            : prevState[type].filter((item) => item !== value);
          return { ...prevState, [type]: newArray };
        });
        setError((prevState) => ({ ...prevState, [type]: "" }));
      };

      const handleSubmit = () =>{

      }
  return (
    <div className=' h-screen p-3 w-full bg-slate-100'>
     
    <div className='flex  p-2 w-full h-full space-x-5' >
    <div className='w-1/5 h-full bg-bluecolor rounded-xl '><Sidebar/></div>
    <div className=' w-4/5   '>
      <div className='px-3 bg-white rounded-md py-2'>
      {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
      <Topbar  name='Update Xpllora Student' />
      
      </div>
      
     <div className='   mt-5   h-[83vh] overflow-auto  '>
      <div className=' h-auto  rounded-md'>
        <div className=''> 
        <form>
               
                    {/* student details */}
                    <div className="bg-white p-5 rounded-md">
                      <p className="font-bold mt-3 px-3 ">Student Detail :</p>
                      {/* fullname */}
                      <div className="flex space-x-5 mt-2 text-gray-700">
                      <div className="flex flex-col   mt-3  w-full px-3  ">
                        <label
                          className="block uppercase tracking-wide  text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Full Name:
                        </label>
                        <input
                          value={xstudent.name}
                          name="name"
                          onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
               `}
                          type="text"
                          placeholder="first name"
                        />
                      </div>

                      {/* date of birth */}
                      <div className="flex flex-col   mt-3  w-full px-3  ">
                        <label
                          className="block uppercase tracking-wide  text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Date Of Birth
                        </label>
                        <input
                          value={xstudent.dateofbirth}
                          name="dateofbirth"
                          onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
               `}
                          type="text"
                          placeholder="YY-MM-DD"
                        />
                      </div>
                      </div>
                      {/* NIc no */}
                      <div className="flex space-x-5 mt-2 text-gray-700">
                      <div className="flex flex-col  mt-3  w-full px-3  ">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          NIC/postal id no
                        </label>
                        <input
                          value={xstudent.nic}
                          name="nic"
                          onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
               `}
                          id="grid-productname"
                          type="text"
                          placeholder="NIC NO"
                        />
                      </div>
                      {/* address */}
                      <div className="flex flex-col   mt-3  w-full px-3  ">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Address
                        </label>
                        <input
                          value={xstudent.address}
                          name="address"
                          onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
               `}
                          id="grid-productname"
                          type="text"
                          placeholder="Address"
                        />
                      </div>
                      </div>
                      <div className="flex flex-col  mb-3 mt-5  w-full px-3  ">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2 text-gray-700"
                          for="grid-first-name"
                        >
                          Contact No
                        </label>
                        <div className="flex space-x-3 ">
                          <input
                            value={xstudent.contact_lan}
                            name="contact_lan"
                            onChange={handleChange}
                            className={`appearance-none block w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
            `}
                            type="text"
                            placeholder="Land Line"
                          />
                          <input
                            value={xstudent.contact_mobile}
                            name="contact_mobile"
                            onChange={handleChange}
                            className={`appearance-none block w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
            `}
                            type="text"
                            placeholder="Mobile "
                          />
                        </div>
                      </div>
                    </div>
                    {/* course Details */}

                    <div >
                      <div className="bg-white p-5 rounded-md mt-5">
                      <p className="font-bold mt-3 text-lg ">Course Detail :</p>
                      <div className="grid grid-cols-4 mt-3 text-gray-700 ">
                        {course.map((c) => (
                          <div className="mb-5">
                            <input
                              value={c.name}
                              className="text-2xl"
                              type="checkbox"
                              checked={xstudent.course.includes(c.name)}
                              onChange={(e) =>
                                handleCheckboxChange(e, "course")
                              }
                            />
                            <span className="ml-2 capitalize tracking-wide text-md font-semibold mb-2">
                              {c.name}{" "}
                            </span>
                          </div>
                        ))}
                      </div>
                      <p className="font-semibold ">Elocution:</p>
                      <div className="grid grid-cols-3 mt-3  text-gray-700">
                        {elucution.map((e) => (
                          <div className="mb-3 ">
                            <input
                              value={e.name}
                              type="checkbox"
                              checked={xstudent.couser_elucution.includes(e.name)}
                              onChange={(e) =>
                                handleCheckboxChange(e, "couser_elucution")
                              }
                            />
                            <span className="ml-2 capitalize tracking-wide text-md font-semobold mb-2">
                              {e.name}{" "}
                            </span>
                          </div>
                        ))}
                      </div>
                      </div>
                      {/* parent Details */}
                      <div className="bg-white p-5 rounded-md mt-5 text-gray-700">
                      <div >
                        <p className="font-bold mt-3 text-lg ">
                          Parent Detail :
                        </p>
                      </div>
                      <div className="flex flex-row">
                        <div className="flex flex-col   mt-2  w-1/2   ">
                          <label
                            className="block uppercase tracking-wide text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                            Name:
                          </label>
                          <input
                            value={xstudent.parent_name}
                            name="parent_name"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
               `}
                            id="grid-productname"
                            type="text"
                            placeholder=" name"
                          />
                        </div>
                        <div className="flex flex-col   mt-2  w-1/2 px-3  ">
                          <label
                            className="block uppercase tracking-wide text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                            Contact No
                          </label>
                          <input
                            value={xstudent.parent_contact}
                            name="parent_contact"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
               `}
                            id="grid-productname"
                            type="text"
                            placeholder="contact no"
                          />
                        </div>
                      </div>
                      <div className="flex ">
                      <div className="flex flex-col   mt-2  w-1/2  ">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Address
                        </label>
                        <input
                          value={xstudent.parent_address}
                          name="parent_address"
                          onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
               `}
                          id="grid-productname"
                          type="text"
                          placeholder="Address"
                        />
                      </div>
                      <div className="flex flex-col w-1/2 px-3 mt-2">
                      <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                    Nic
                        </label>
                        <input
                          // value={xstudent.parent_address}
                          // name="parent_address"
                          // onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
               `}
                          id="grid-productname"
                          type="text"
                          placeholder="Parent NIC"
                        />
                      </div>
                      </div>
                      </div>
                    </div>
              
                  <div className="flex justify-end mr-10 mt-8 pb-10">
                    <button
                      className="bg-bluecolor px-10 py-3 w-44 rounded-md  font-bold text-white"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
        </div>
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Updatexstudent
