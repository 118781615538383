import React from 'react'
import { useSelector } from 'react-redux';
import { FaUserCircle } from "react-icons/fa";

const Topbar = ({name}) => {
  // const {user} = useSelector((state) =>state.userState)

  // const staff = user.result[0]
  const {users} = useSelector((state) => state.userState2)
  console.log(users);
  const staff = users.result[0]
    // console.log(user.result[0]);
 
  return (
    <>
    
       <div className='py-2 flex items-center justify-between '>
        <p className='text-[#e01e1e] font-bold text-xl'>{name}</p>
        <div className='flex items-center space-x-2'>
          <FaUserCircle className='text-2xl text-bluecolor'/>
      <p className='text-bluecolor font-semibold '> {staff.name}</p>
      </div>
     </div>
  
   
    </>
  )

}

export default Topbar