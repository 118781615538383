import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import Topbar from "../components/Topbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { EditStaff, SingleStaff } from "../slice/staff";

const Updatestaff = () => {
  const { id } = useParams();
  console.log(id);
  const { updateStaff, singlestaff } = useSelector((state) => state.Employee);
  console.log(updateStaff);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(SingleStaff(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (Array.isArray(singlestaff.Result) && singlestaff.Result.length > 0) {
      const staffData = singlestaff.Result[0];
      console.log(staffData);
      setStaffDetails({
        // ...staffDetails,
        lastname: singlestaff.Result[0].name,
        email: singlestaff.Result[0].email,
        phone: singlestaff.Result[0].phone,
        role: singlestaff.Result[0].role,
        password: singlestaff.Result[0].password,
        branch_id: singlestaff.Result[0].branch_id,
      });
    }
  }, [singlestaff]);

  useEffect(() => {
    if (updateStaff && updateStaff.status !== undefined) {
      if (updateStaff.status) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Staff Update Successfuly!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          navigate("/allStaff");
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Student Added Successfuly!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          navigate("/allStaff");
        });
      }
    }
  });

  const [branch, setBranch] = useState([]);

  const [staffDetails, setStaffDetails] = useState({
    lastname: "",
    email: "",
    phone: "",
    role: "",
    password: "",
    branch_id: "",
  });
  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStaffDetails({ ...staffDetails, [name]: value });
    setError({ ...error, [name]: "" });
  };

  //get branch data
  useEffect(() => {
    const getBranch = () => {
      axios
        .get("https://xpllora.teammatrixmantra.com/branch/getStaff")
        .then((response) => setBranch(response.data))
        .catch((error) => console.log(error));
    };
    getBranch();
  }, []);

  // handle to submit data
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(EditStaff(id, staffDetails));
  };

  return (
    <div className=" h-screen p-3 w-full bg-slate-100">
      <div className="flex  p-2 w-full h-full space-x-5">
        <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
          <Sidebar />
        </div>
        <div className=" w-4/5   ">
          <div className="px-3 bg-white rounded-md py-2">
            {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
            <Topbar name="Update Staff" />
          </div>

          <div className="  mt-5 bg-white p-3  rounded-md">
            <div className=" h-full  ">
              <div className="">
                <form>
                  <div className="px-5 py-2  ">
                    <h1 className="font-bold  px-3 mt-3">Staff Details:</h1>
                    {/* staff name and email */}
                    <div className="flex items-center space-x-3 mt-3">
                      <div className="flex flex-col  w-1/2 px-3 py-3  ">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Staff Name
                        </label>
                        <input
                          value={staffDetails.lastname}
                          name="lastname"
                          onChange={handleChange}
                          className={`appearance-none block w-full  bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                          type="text"
                          placeholder="staff name"
                        />
                      </div>
                      <div className="flex flex-col w-1/2  px-3 py-3 ">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Email
                        </label>
                        <input
                          value={staffDetails.email}
                          name="email"
                          onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                          id="grid-productname"
                          type="text"
                          placeholder="email"
                        />
                      </div>
                    </div>
                    {/* staff phone and password */}
                    <div className="flex items-center space-x-3 mt-3">
                      <div className="flex flex-col w-1/2 p-3 ">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          phone_no
                        </label>
                        <input
                          value={staffDetails.phone}
                          name="phone"
                          onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                          id="grid-productname"
                          type="text"
                          placeholder="phone number"
                        />
                      </div>
                      <div className="flex flex-col w-1/2  p-3 ">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Password
                        </label>
                        <input
                          value={staffDetails.password}
                          name="password"
                          onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                          id="grid-productname"
                          type="text"
                          placeholder="password"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row w-full">
                      <div className=" w-full flex flex-col  p-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Role
                        </label>
                        <select
                          value={staffDetails.role}
                          name="role"
                          onChange={handleChange}
                          id=""
                          className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor"
                        >
                          <option>select</option>
                          <option value="admin">Admin</option>
                          <option value="staff">Staff</option>
                        </select>
                      </div>
                      <div className=" w-full flex flex-col  p-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Branch
                        </label>

                        <select
                          value={staffDetails.branch_id}
                          name="branch_id"
                          onChange={handleChange}
                          id=""
                          className="block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor"
                        >
                          <option>select</option>
                          {branch.map((branch) => (
                            <option value={branch.branch_id}>
                              {branch.branch_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className=" w-full flex items-center justify-end mt-5 p-3 px-5">
                    <button
                      className="py-2 bg-bluecolor w-48  rounded-md text-white "
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Updatestaff;
