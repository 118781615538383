import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialstate ={
    activity :[],
    isCreated :{},
    singleActivity:{},
    isUpdate:{}
    
}
const extraActivity = createSlice(
    {
        name: "extraActivity",
        initialState: initialstate,
        reducers:{
            extraActivityCreated(state,action){
                state.isCreated = action.payload;
            },
            getAllActivity(state,action){
                state.activity = action.payload
            },
            clearActivityCreated(state,action){
            state.isCreated = {}
            },
            getsingleactivity(state,action){
                state.singleActivity= action.payload
            },
            updateActivity(state,action){
                state.isUpdate= action.payload
            },
            clearActivityUpdate(state,action){
                state.isUpdate = {}
            }
        }
    
    }
)
export const {extraActivityCreated, getAllActivity,clearActivityCreated,getsingleactivity,updateActivity,clearActivityUpdate} = extraActivity.actions
export default extraActivity.reducer


export function addNewActivity (courseData){
    return async function addNewactivityThunk (dispatch,getState) {
        const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/extraactivity/addnew`,courseData)
        const result =data.data
    dispatch(extraActivityCreated(result))
        console.log(result);
    }
}


export function AllActivities (){
    return async function activityThunk (dispatch,getState) {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/extraactivity/getallactivity`)
        const result =data.data
    dispatch(getAllActivity(result))
        console.log(result);
    }
}



export function getSingleExtraActivity (id){
    return async function getsingleactivityThunk (dispatch,getState) {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/extraactivity/getsinglectivity/${id}`)
        const result =data.data
    dispatch(getsingleactivity(result))
        console.log(result);
    }
}


export function updateExtraActivity (id,activityData){
    return async function getsingleactivityThunk (dispatch,getState) {
        const data = await axios.put(`${process.env.REACT_APP_BASE_URL}/extraactivity/updateextraactivity/${id}`,activityData)
        const result =data.data
    dispatch(updateActivity(result))
        console.log(result);
    }
}