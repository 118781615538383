import React from 'react'

const Lodinng = () => {
  return (
    <div className='flex items-center justify-center '>
     <p className='text-6xl  font-bold'>Comming Soon....</p>
    </div>
  )
}

export default Lodinng
