import React, { useEffect, useState } from 'react'

import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useSelector } from 'react-redux'
import Topbar from '../../components/Topbar'
import Sidebar from '../../components/Sidebar'
import axios from 'axios'

const Parent = () => {
    const [parent,setParent] = useState([])
    console.log(parent);
    useEffect(() =>{
      const getDate = async() =>{
         axios.get(`${process.env.REACT_APP_BASE_URL}/prestudent/getallparents`).then((result) =>{
            setParent(result.data.Result)
         }).catch((err) => console.log(err))
      } 
      getDate() 
    },[])
    console.log(parent);
  return (
    <div className=' h-screen p-3 w-full bg-slate-100'>
     
    <div className='flex  p-2 w-full h-full space-x-5' >
    <div className='w-1/5 h-full bg-bluecolor rounded-xl '><Sidebar/></div>
    <div className=' w-4/5   '>
      <div className='px-3 bg-white rounded-md py-2'>
      {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
      <Topbar  name='Parent Details' />
      
      </div>
    
     <div className=' mt-5   h-[83vh] overflow-auto'>
      <div className='  bg-white rounded-md '>
        <div className=''>
        <table className="mt-5 w-full text-left   ">
        <thead className='mt-5  '>
          <tr className="text-graycolor bg-bluecolor  ">
            <th className="px-3 py-3">Student Name</th>
            <th className="px-3 py-3">Father Name</th>
            <th className="px-3 py-3">Father Occuptaion</th>
            <th className="px-3 py-3">Mother Name</th>
            <th className="px-3 py-3">Mother Occuptaion</th>
          </tr>
        </thead>
        <tbody className="  ">
          {parent.map((student) => (
            <tr  className={`mt-3 border-b-[1px] border-b-gray-100 text-bluecolor `}
             >
             
             <td className="px-3 py-4 ">
              <p className="font-medium">{student.firstname}</p>
              <p className="text-graycolor ">{student.lastname}</p>
              </td>
              <td className="px-3 py-4 ">
              <p className="font-medium">{student.name}</p>
              <p className="text-graycolor ">{student.phone}</p>
              </td>
             <td className="px-3 py-4 ">{student.father_occupation}</td>
             <td className="px-3 py-4 ">
              <p className="font-medium">{student.mother_name}</p>
              <p className="text-graycolor ">{student.mother_phone}</p>
              </td>
              <td className="px-3 py-4 ">{student.mother_occupation}</td>
            </tr>

          ))}
         
        </tbody>
      </table> </div>
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Parent
