

import {  Outlet } from "react-router-dom";
import store from './store'

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadUser } from "./action/UserAction";
import Header from "./userUI/components/Header";


function App() {
console.log(process.env.BASE_URL);

  const {user} = useSelector((state) =>state.userState)
  useEffect(() =>{
    store.dispatch(loadUser)
    console.log("singleuser",user.result);
  },[loadUser])
  return (
    <div>
     
      <div>
      <Outlet />
      </div>
  
    </div>

 
  );
}

export default App;
