import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RemoveStaff } from "../action/StaffAction";
import Sidebar from "../components/Sidebar";
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import Topbar from "../components/Topbar";
import { deleteStaffAction, getStaff } from "../slice/staff";

const Allstaff = () => {
  const { staff } = useSelector((state) => state.Employee);

  const dispatch = useDispatch();
  console.log("all staff", staff);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getStaff());
  }, [dispatch]);

  const handleRemove = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(id);
        dispatch(deleteStaffAction(id)).then(()=>{
          dispatch(getStaff())
        })
      }
    });
  };

  const handleUpdate = (id) => {
    console.log(id);
    navigate(`/updatestaff/${id}`);
  };
  return (
    <div className=" h-screen p-3 w-full bg-slate-100">
      <div className="flex  p-2 w-full h-full space-x-5">
        <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
          <Sidebar />
        </div>
        <div className=" w-4/5   ">
          <div className="px-3 bg-white rounded-md py-2">
            {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
            <Topbar name="All Staff" />
          </div>

          <div className="  mt-5 bg-white  h-[83vh]  overflow-auto">
            <div className=" h-full  rounded-md">
              <div className="">
                <div className="">
                  <table className="  w-full text-left   ">
                    <thead className="bg-bluecolor">
                      <tr className="text-graycolor">
                        <th scope="col" className="px-6 py-3">
                          Staff Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Branch
                        </th>
                        <th scope="col" className="px-6 py-3">
                          role
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Email
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Phone
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {staff.map((staff) => (
                        <tr className="mt-3 border-b-[1px] border-b-gray-100 ">
                          <td className="px-6 py-4">{staff.name}</td>
                          <td className="px-6 py-4">{staff.branch_name}</td>
                          <td className="px-6 py-4">{staff.role}</td>
                          <td className="px-6 py-4">{staff.email}</td>
                          <td className="px-6 py-4">{staff.phone}</td>
                          <td className="px-6 py-4 flex space-x-2 items-center">
                            <button
                              className="font-medium text-xl text-blue-600 dark:text-blue-500 hover:underline"
                              onClick={() => handleUpdate(staff.staff_id)}
                            >
                              <FaEdit />
                            </button>
                            <button
                              className="font-medium text-xl text-red-600 dark:text-red-500 hover:underline"
                              onClick={() => handleRemove(staff.staff_id)}
                            >
                              <MdDelete />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Allstaff;
