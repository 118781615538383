import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getStudentProfile } from '../../slice/student'

import Sidebar from '../../components/Sidebar'

import Topbar from '../../components/Topbar'

const SInglestudent = () => {
    const {studentProfile} = useSelector(state => state.studentState)
    console.log(studentProfile);
    const  {id} = useParams()
    console.log(id);
 const dispatch = useDispatch()
    useEffect(() => {
     dispatch(getStudentProfile(id))
    },[dispatch])
  return (
    <div className=' h-screen p-3 w-full bg-bluecolor'>
     
    <div className='flex  p-2 w-full h-full' >
    <div className='w-1/5 h-full bg-blue'><Sidebar/></div>
    <div className=' w-4/5 rounded-md  '>
      <div className='py-2 flex items-center justify-between'>
      <p className=' font-bold text-2xl text-graycolor'>Single Student</p>
      <Topbar />
      
      </div>
     <div className='bg-graycolor p-3 h-[95%]'>
      <div className=' h-full bg-white rounded-md'>
        <div className='flex '>
            <div className='mt-10'>
                <img src='https://img.freepik.com/premium-photo/young-smiling-student-woman-holds-books-while-looking-camera-white-background-education_136403-15774.jpg' />
            </div>
            {studentProfile.map((student) => (
             <div className='mt-10'>
                <div className='flex '>
             <p>Full Name: </p>
             <p>{student.firstname}  {student.lastname}</p>
             </div>
             <div className='flex '>
             <p>Student ID(new): </p>
             <p>{student.id} </p>
             </div>
             <div className='flex '>
             <p>Student ID(old): </p>
             <p>{student.old_id}</p>
             </div>
             <div className='flex '>
             <p>Grade: </p>
             <p>{student.grade}</p>
             </div>
             <div className='flex '>
             <p>Grade: </p>
             <p>{student.grade}</p>
             </div>
         </div>
            ))}
           
             </div>
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default SInglestudent
