import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "../../components/Topbar";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { deleteStudent, getStudent, getStudentAdmin } from "../../slice/student";
import { GrView } from "react-icons/gr";
import { BsThreeDots } from "react-icons/bs";
import { MdSearch } from "react-icons/md";
import studentimg from '../../image/icons/student.png'
import { getStudentProfile } from '../../slice/student'
import Swal from "sweetalert2";

const Allstudents = () => {
  // const { user } = useSelector((state) => state.userState);
  const {users} = useSelector((state) => state.userState2)
  console.log(users);
  const {studentProfile} = useSelector(state => state.studentState)
  const dispatch = useDispatch();
  // const branchid = user.result[0].branch_id;
  // const role = user.result[0].role;
  const branchid = users.result[0].branch_id;
  const role = users.result[0].role;
  const navigate = useNavigate();
  const { studentData } = useSelector((state) => state.studentState);
  

  useEffect(() => {
    if (role == "admin") {
      dispatch(getStudentAdmin());
    } else {
      dispatch(getStudent(branchid));
    }
  }, []);

  const handleUpdate = (id) => {
    navigate(`/updatePrestudent/${id}`);
  };

const [selectedStudentId, setSelectedStudentId] = useState(null);
const handleRowClick = (id) => {
  setSelectedStudentId(id);
  dispatch(getStudentProfile(id));
};
const [searchId,setSearchId] = useState()
const [filteredStudents, setFilteredStudents] = useState([]);
const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
    console.log(searchId); // Assuming searchId and searchName are your state variables for ID and Name search inputs
    const filtered = studentData.filter((student) => {
      // Filter by ID and/or Name (case insensitive)
      return (
        student.id == searchId ||
        student.firstname.toLowerCase().includes(searchId.toLowerCase())
      );
    });
    setFilteredStudents(filtered);
  }
};

const handleDelete =(id)=>{
console.log(id);
Swal.fire({
  title: "Are you sure?",
  text: "You won't be able to revert this!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, delete it!"
}).then((result) => {
  if (result.isConfirmed) {
    dispatch(deleteStudent(id)).then(() => {
      if (role === "admin") {
        dispatch(getStudentAdmin());
      } else {
        dispatch(getStudent(branchid));
      }
    });
    
  }
});

}
  return (
    <div className=' h-screen p-3 w-full bg-slate-100'>
     
    <div className='flex  p-2 w-full h-full space-x-5' >
    <div className='w-1/5 h-full bg-bluecolor rounded-xl '><Sidebar/></div>
    <div className=' w-4/5   '>
      <div className='px-3 bg-white rounded-md py-2'>
      {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
      <Topbar  name='All Pre Student' />
      
      </div>
    
     <div className='  mt-5   h-[83vh] w-full flex space-x-5 '>
     <div className="bg-white w-1/2   rounded-md p-3">
      <div className="flex justify-between items-center">
        <p className="text-bluecolor text-xl font-semibold">Students</p>
        <button
  className="bg-bluecolor px-5 py-2 rounded-md text-white text-xs "
  onClick={() => navigate("/addstudent")}
>
  ADD
  </button>
      </div>
      <div className="mt-5  flex space-x-3  items-center border-2 border-gray-200 w-full rounded-md px-2 py-2 placeholder:text-graycolor ">
        <MdSearch className="text-graycolor text-2xl" />
        <input value={searchId} onChange={(e) => setSearchId(e.target.value)}  onKeyPress={handleKeyPress} type="text" placeholder="search for students or ID" 
        className="hover:border-transparent focus:outline-none w-full" />
      </div>
      <div className="border-b-2 border-b-gray-300 mt-8"></div>
      <div className="overflow-auto h-[63vh]">
      <table className="mt-8 w-full text-left  ">
        <thead>
          <tr className="text-graycolor">
            <th className="px-3 py-3">Photo</th>
            <th className="px-3 py-3">Name</th>
            <th className="px-3 py-3">Student ID</th>
            <th className="px-3 py-3">Action</th>
          </tr>
        </thead>
        <tbody className="  ">
          {(searchId ? filteredStudents : studentData).map((student) => (
            <tr  className={`mt-3 border-b-[1px] border-b-gray-100 text-bluecolor ${selectedStudentId === student.id ? 'bg-bluecolor text-white' : ''}`}
             onClick={() => handleRowClick(student.id)}>
              <td className="px-3 py-4 ">
                <img src={`${process.env.REACT_APP_BASE_URL}/Images/`+student.photo} alt="student photo" className="w-20 h-20 rounded-full object-cover" />
              </td>
             <td className="px-3 py-4 ">
              <p className="font-medium">{student.firstname}</p>
              <p className="text-graycolor ">{student.lastname}</p>
              </td>
             <td className="px-3 py-4 ">{student.id}</td>
             <td className="px-3 py-4 flex space-x-2 items-center">
             {/* <button className="font-medium text-xl text-green-600 dark:text-green-500 hover:underline"
        onClick={() => handleView(student.id)}>
            <GrView/>
          </button> */}
          <button
            className="font-medium text-lg text-blue-400  hover:text-blue-500"
            onClick={() => handleUpdate(student.id)}
          >
            <FaEdit />
          </button>
          <button className="font-medium text-lg text-red-400 hover:text-red-500" 
          onClick={()=> handleDelete(student.id)}>
            <MdDelete />
          </button>
             </td>
          
            </tr>

          ))}
         
        </tbody>
      </table>
      </div>
     </div>


     {/* single student Details */}
     {studentProfile.map((student) =>(
     <div className="bg-white w-1/2  rounded-md h-[83vh] overflow-auto">
      <div className="bg-bluecolor  rounded-t-md px-8 py-3 flex space-x-10 items-center ">
      <img src={`${process.env.REACT_APP_BASE_URL}/Images/`+student.photo} alt='' className='h-32 w-32 object-cover rounded-full border-2 border-textcolor p-2' />
      <div className="text-white ">
      <p className="font-bold text-xl">{student.firstname} {student.lastname}</p>
      <p>{student.coursename} | Student ID : {student.id}</p>
      </div>
      </div>
      <div className="px-5 py-5 ">
        {/* student information */}
      <div className="border-2 border-gray-200 rounded-md  p-5  ">
        <p className="text-bluecolor text-xl font-medium">Basic Details</p>
        <div className="grid grid-cols-4">
          <div className="mt-3">
            <p className="text-textcolor text-sm">Date of Birth</p>
            <p className="text-bluecolor font-medium">{student.dateofbirth}</p>
          </div>
          <div className="mt-3">
            <p className="text-textcolor text-sm">Religion</p>
            <p className="text-bluecolor font-medium">{student.religion}</p>
          </div>
          <div className="mt-3">
            <p className="text-textcolor text-sm">Medium</p>
            <p className="text-bluecolor font-medium">{student.medium}</p>
          </div>
          <div className="mt-3">
            <p className="text-textcolor text-sm">Mother Tounge</p>
            <p className="text-bluecolor font-medium">{student.mother_tounge}</p>
          </div>
         
        </div>
        <div className="grid grid-cols-2 mt-3">
        <div className="mt-3 ">
            <p className="text-textcolor text-sm">Address</p>
            <p className="text-bluecolor font-medium">{student.address}</p>
          </div>
          <div className="mt-3 ">
            <p className="text-textcolor text-sm">Sibiling</p>
            <p className="text-bluecolor font-medium">{student.sibiling_name}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-5 gap-2">
          <div>
          <p className="text-bluecolor font-bold">Father Details</p>
          <div className="mt-2">
            
            <p className="text-bluecolor font-medium"><span className="text-textcolor text-sm">Name : </span>{student.name}</p>
          
            <p className="text-bluecolor font-medium"><span className="text-textcolor text-sm">Phone : </span>+94{student.phone}</p>
            <p className="text-bluecolor font-medium"><span className="text-textcolor text-sm">Occupation : </span>{student.father_occupation}</p>
          </div>
      
          </div>
          <div>
          <p className="text-bluecolor font-bold">Mother Details</p>
          <div className="mt-2">
            
            <p className="text-bluecolor font-medium"><span className="text-textcolor text-sm">Name : </span>{student.mother_name}</p>
          
            <p className="text-bluecolor font-medium"><span className="text-textcolor text-sm">Phone : </span>+94{student.mother_phone}</p>
            <p className="text-bluecolor font-medium"><span className="text-textcolor text-sm">Occupation : </span>{student.mother_occupation}</p>
          </div>
      
          </div>
      
         
         
        </div>
      </div>

      {/* student payment information */}
      <div className="border-2 border-gray-200 rounded-md  p-5 h-52 mt-5" >
        <p className="text-bluecolor text-xl font-medium">Paymeny Details</p>
         </div>
     </div>
     </div>
     ))}
      </div>
    </div>
    </div>
    </div>
  );
};

export default Allstudents;
