import React, { useEffect, useState } from "react";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { clearUpdate, courseUpdate, getSingleCourse } from "../slice/course";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";


function Updatecourse() {
  const { single_course,isUpdated } = useSelector((state) => state.courseState);
console.log(isUpdated);
  const { id } = useParams();
  console.log(id);
const navigate = useNavigate()
  const [course, setCourse] = useState({
    name: "",
    description: "",
    fees: "",
  });

  const [gradeLevels, setGradeLevels] = useState([{ grade_level: "" }]);
  const [error, setError] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleCourse(id));
    console.log(single_course);
  }, [dispatch, id]);

  useEffect(() => {
    if (
      Array.isArray(single_course.Result) &&
      single_course.Result.length > 0
    ) {
      const courseData = single_course.Result[0];
      const parsedGradeLevels = JSON.parse(courseData.grade_levels);
      const name = courseData.name;
      const description = courseData.description;
      const fees = courseData.fees;
      setCourse({
        name: name,
        description: description,
        fees: fees,
      });
      setGradeLevels(parsedGradeLevels);
    } else {
      console.error("No Course data found or Result is not an array");
    }
  }, [single_course]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourse({ ...course, [name]: value });
    setError({ ...error, [name]: "" });
  };
  const validate = () => {
    const error = {};
    let isValid = true;
    if (!course.name) {
      error.name = "Course Name is Required";
      isValid = false;
    }
    if (!course.fees) {
      error.fees = "Course Fees is Required";
      isValid = false;
    }
    if (!course.description) {
      error.description = "Description is Required";
      isValid = false;
    }
    setError(error);
    return isValid;
  };

  const addGradeLevel = () => {
    setGradeLevels([...gradeLevels, { grade_level: "" }]);
  };
  const handleGradeChange = (index, e) => {
    const { name, value } = e.target;
    const newGradeLevels = [...gradeLevels];
    newGradeLevels[index][name] = value;
    setGradeLevels(newGradeLevels);
    setError({ error, [name]: "" });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      const courseData = {
        ...course,
        gradeLevels,
      };
      console.log(courseData);
      dispatch(courseUpdate(id, courseData));
    }
  };
  useEffect(() =>{
    if(isUpdated && isUpdated.Status !== undefined){
      if (isUpdated.Status) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Course Update Successfuly!",
          showConfirmButton: false,
          timer: 1500,
        });
       dispatch(clearUpdate())
        setTimeout(() => {
          navigate("/courses");
        }, 1000);
      } else {
        alert("Error");
      }
    }
  })

  return (
    <div className=" h-screen p-3 w-full bg-slate-100">
      <div className="flex  p-2 w-full h-full space-x-5">
        <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
          <Sidebar />
        </div>
        <div className=" w-4/5   ">
          <div className="px-3 bg-white rounded-md py-2">
            {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
            <Topbar name="Course Update" />
          </div>

          <div className="  mt-5 bg-white p-3 h-[83vh] rounded-md overflow-auto ">
            <div className=" h-full  ">
              <div className="">
                <form>
                  <div className="flex flex-col w-full px-5">
                    <p className="font-bold mt-3 text-lg">
                      Add Course Detail :
                    </p>
                    <div className="flex flex-wrap -mx-3 mb-3 mt-3">
                      <div className="w-full px-3 mb-3 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Course Name
                        </label>
                        <input
                          value={course.name}
                          name="name"
                          onChange={handleChange}
                          className={`appearance-none block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                        ${error.name ? "border-red-500" : ""}`}
                          type="text"
                          placeholder="Course Name"
                        />
                        {error.name && (
                          <p className="text-red-500 text-xs ">{error.name}</p>
                        )}
                      </div>
                      <div className="w-full px-3 mt-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Description
                        </label>
                        <input
                          value={course.description}
                          name="description"
                          onChange={handleChange}
                          className={`appearance-none block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                        ${error.description ? "border-red-500" : ""}`}
                          type="text"
                          placeholder="Description"
                        />
                        {error.description && (
                          <p className="text-red-500 text-xs">
                            {error.description}
                          </p>
                        )}
                      </div>
                      <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Course Fees
                        </label>
                        <input
                          value={course.fees}
                          name="fees"
                          onChange={handleChange}
                          className={`appearance-none block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                        ${error.fees ? "border-red-500" : ""}`}
                          type="text"
                          placeholder="Fees"
                        />
                        {error.fees && (
                          <p className="text-red-500 text-xs">{error.fees}</p>
                        )}
                      </div>
                    </div>
                    {/* <div className="flex items-center justify-start space-x-5">
                      <div className="w-3/4 px-1">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Grade Levels
                        </label>
                        {gradeLevels.map((grade, index) => (
                          <input
                            key={index}
                            value={grade.grade_level}
                            name="grade_level"
                            onChange={(e) => handleGradeChange(index, e)}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor"
                            type="text"
                            placeholder="Grade Level"
                          />
                        ))}
                      </div>
                      <button
                        type="button"
                        onClick={addGradeLevel}
                        className="px-4 py-2 mt-2 text-white bg-bluecolor rounded-full hover:bg-graycolor"
                      >
                        +
                      </button>
                    </div> */}

                    <div className="flex mb-5 py-5 md:justify-end justify-center">
                      <button
                        className="px-5 py-2 text-white bg-bluecolor rounded-md w-56 hover:bg-graycolor text-xl"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Updatecourse;
