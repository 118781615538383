import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialstate = {
    staff : [],
    isStaffCreated: {},
    updateStaff:{},
    singlestaff:[],
    isDelete:{}
}

const staffSlice = createSlice({
    name: 'staff',
    initialState: initialstate,
    reducers: {
        addNewStaff (state,action){
            state.isStaffCreated = action.payload
        },
        clearStaffCrated(state,action){
            state.isStaffCreated = {}
        },
        getAllStaff(state,action){
            state.staff = action.payload
        },
        getSingleStaff(state,action){
           state.singlestaff= action.payload
        },
        updateStaff(state,action){
            state.updateStaff=action.payload
        },
        deleteStaff(state,action){
           state.isDelete=action.payload
        }
    }
})

 export const {addNewStaff, clearStaffCrated,getAllStaff,updateStaff,getSingleStaff,deleteStaff} = staffSlice.actions
export default staffSlice.reducer

export  function  AddStaff (staff) {
    return async function addNewstaffThunk(dispatch, getState)  {
        const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/newstaff`,staff)
        const result =data.data
      dispatch(addNewStaff(result))
        console.log(result);

 }
}
export  function  getStaff () {
    return async function getstaffThunk(dispatch, getState)  {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/allstaff`)
        const result =data.data
      dispatch(getAllStaff(result))
        console.log(result);

 }
}
export  function  EditStaff (id,studentData) {
    return async function getstaffThunk(dispatch, getState)  {
        const data = await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/updatestaff/${id}`,studentData)
        const result =data.data
      dispatch(updateStaff(result))
        console.log(result);

 }
}

export  function  SingleStaff (id) {
    return async function getsinglestaffThunk(dispatch, getState)  {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/singlestaff/${id}`)
        const result =data.data
      dispatch(getSingleStaff(result))
        console.log(result);

 }
}

export  function deleteStaffAction (id) {
    return async function deletestaffThunk(dispatch, getState)  {
        const data = await axios.delete(`${process.env.REACT_APP_BASE_URL}/auth/remove/${id}`);
        const result =data.data
      dispatch(deleteStaff(result))
        console.log(result);

 }
}